import * as React from "react"
import Layout from '../components/Layout'
import { useStaticQuery, graphql, Link } from "gatsby"


export default function Archive() {

    const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: {frontmatter: {slug: {regex: "/news/"}}}
        sort: {order: DESC, fields: [frontmatter___date]}
      ){
        edges {
          node {
            html
            frontmatter {
              slug
              date
              title
              description
            }
          }
        }
      }
    }
    `);

  return (

    <Layout>
        <div className="archive">
          <h3 className="breakpoint"> News Archive </h3>
        {(data.allMarkdownRemark.edges).map((e) => 
          {
            return(
              <div className="article">
                <div className="date">{e.node.frontmatter.date}</div>
                <div><Link to={e.node.frontmatter.slug}>{e.node.frontmatter.title}</Link></div>
              </div>
            )
          }

        )}
        </div>
    </Layout>
  )
} 
